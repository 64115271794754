.fadeIn {
	animation: fade-in 0.5s forwards;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.fadeOut {
	animation: fade-out 0.5s forwards;
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
// .border {
// 	width: 90%;
// 	height: 35px;
// 	border: solid 10px #fff;
// 	border-color: #fff transparent transparent transparent;
// 	border-radius: 70% 25px 25px 0 0;
// 	position: absolute;
// 	margin-top: -40px;
// 	opacity: 0.4;
// }
.wheel-central-logo{
	background-image:url(../../../../assets/adylic-stacked-logo-white.png);
	background-repeat: no-repeat;
	background-size:contain;	
	width:200px;
	height:200px;
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
	@media screen and (max-width: 3842px) {
		width: 3.3vw;
		height: 3.3vw;
		font-size: 1.04vw;
	}
	@media screen and (max-width: 600px) {
		width: 3.3vw;
		height: 3.3vw;
		font-size: 2.5vw;
	}
}

.wheel-skin {
	position: relative;
	width: 650px;
	height: 650px;
	border-radius: 50%;
	overflow: hidden;
	background-image: url(../../../../assets/wheel-skin.png);
	font-weight: 500;
	font-size: 20px;
	background-size: 135%;
	background-position: center;
	will-change: transform;
	@media screen and (max-width: 3842px) {
		width: 33.85vw;
		height: 33.85vw;
		font-size: 1.04vw;
	}
	@media screen and (max-width: 600px) {
		width: 70vw;
		height: 70vw;
		font-size: 2.5vw;
	}
}
.wheel {
	width: 100%;
	height: 100%;
	transition: transform 1.5s ease;
	position: relative;
	font-size: 0.8vw;
    font-weight: bold;
	text-transform: uppercase;
	@media screen and (max-width: 600px) {
		font-size: 1.7vw;
	}
	.wedge {
		outline: none;
		background-color: hotpink;
		position: absolute;
		cursor: pointer;
		width: 100%;		
		height: 100%;
		left: 50%;
		bottom: 50%;
		background-clip: content-box;
		transform-origin: bottom left;
		transition: opacity 200ms ease-out 2s;
		padding: 20px;
		border-radius: 30px;
		@media screen and (max-width: 3842px) {
			padding: 1.04vw;
			border-radius: 1.56vw;
		}

		&.media {
			background-color: lighten(#dab1d3, 8%);			
		}
		&.creative {
			background-color: #96cfbc;
			background-color: lighten(#96cfbc, 8%);	
		}
		&.ad-tech {
			background-color: #d1d1d1;
			background-color: lighten(#d1d1d1, 8%);	
		}
		&:hover {
			z-index: 1;
		}
		.topBorder{
			width: 7vw;
			height: 1.9vh;
			background-color: #ffffff4f;
			border-radius: 40px/40px 40px 40px 40px;
			position: absolute;
			margin-top: -4.5vh;
			@media screen and(max-width: 3842px) {
				width: 7vw;
				height: 1.9vh;
			}
			@media screen and(max-width: 600px) {
				width: 17vw;
				height: 1.9vh;
			}
		}
		.text {
			position: absolute;
			bottom: 85px;
			padding: 0px;
			left: 0;
			width: 32%;
			color: #274f66;
			text-align: center;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			will-change: transform;
			@media screen and(max-width: 3842px) {
				bottom: 4.427vw;
			}
			@media screen and(max-width: 600px) {
				width: 30%;
				width: 30%;
				bottom: 8.90625vw;
			}
		}

		.title {
		}

		.number {
			margin-bottom: 10px;
			color: #000;
			background-color: #fff;
			border-radius: 9999px;
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			font-size: 1.5vw;
			align-items: center;
			z-index: 1;
			@media screen and(max-width: 3842px) {
				margin-bottom: 0.52vw;
				width: 2.5vw;
				height: 2.5vw;
			}
			@media screen and(max-width: 600px) {
				margin-bottom: 2vw;
				width: 3.5vw;
				height: 3.5vw;
				font-size:2.3vw;
			}
		}
	}
	.wedge.activeWedge {
		opacity: 1;
		transition: opacity 2s linear;				
		.activeBorder {
			width: 100%;
			height: 100%;
			border-left: 6px solid white;
			border-radius: 9px;
			border-bottom: 8px solid white;
			box-shadow: 0px 0px 11px 0px #302f2fb3;
			@media screen and (max-width: 3842px) {
				border-left: 0.3125vw solid white;
				border-radius: 0.46875vw;
				border-bottom: 0.4166vw solid white;
				box-shadow: 0px 0px 11px 0px #302f2fb3;
			}
		}
	}
	.wedge.activeWedge.media{
		background-color: lighten(#dab1d3, 0%);		
	}
	.wedge.activeWedge.creative{
		background-color: lighten(#96cfbc, 0%);	
	}
	.wedge.activeWedge.ad-tech{
		background-color: lighten(#d1d1d1, 0%);	
	}
}
