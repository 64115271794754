.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	// min-height: 100vh;
	// margin-bottom: 100px;
	position: relative;
	@media screen and (max-width: 600px) {
		height: 90vh;
		align-items: start;
	}
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		max-height: 1080px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 33.33% 50.66% 109px;
		padding: 50px;
		gap: 50px;
		@media screen and (max-width: 600px) {
			grid-template-rows: 33.33% 44.66% 99px;
			padding: 25px;
			gap: 25px;
		}
		.top {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			grid-column: 1/2;
			grid-row: 1/2;
			// margin: 0 50px;
			@media screen and (max-width: 600px) {
				// margin: 0 25px;
			}
			.title-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				.title {
					font-weight: bold;
					color: #ffffff;
					font-size: 67px;
					letter-spacing: -1.35px;
					margin-bottom: 25px;
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 3.48vw;
						letter-spacing: -0.07vw;
						margin-bottom: 1.3vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 7.5vw;
					}
				}
				.text {
					font-size: 23px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: -0.5px;
					color: #fff;
					width: 50%;
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 1.19vw;
						letter-spacing: -0.02vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 3.5vw;
						width: 100%;
					}
				}
			}
		}

		.bottom {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-column: 1/2;
			grid-row: 2/3;
			.box-container {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: 1fr;
				grid-column-gap: 40px;
				column-gap: 20px;
				align-items: center;
				height: calc(100% - 100px);
				width: 100%;
				height: 90%;
				opacity: 0;
				@media screen and (max-width: 600px) {
					grid-template-columns: 1fr;
					grid-template-rows: repeat(3, 1fr);
					row-gap: 25px;
					margin: 0px;
					column-gap: 0;
				}
				.column {
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: 1fr;
					height: 100%;
					&:nth-child(1) {
						& > .box {
							background-color: #abdfd3;
						}
					}
					&:nth-child(2) {
						& > .box {
							background-color: #5fc3ad;
						}
					}
					&:nth-child(3) {
						& > .box {
							background-color: #419381;
						}
					}
					.box {
						display: flex;
						justify-content: center;
						align-items: center;
						grid-row: 1/2;
						grid-column: 1/2;
						font-weight: bold;
						line-height: 1.56;
						color: #ffffff;
						padding: 30px;
						// border-radius: 15px;
						border-radius: 30px;
						font-size: 50px;
						letter-spacing: -1.34px;
						box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.25);
						transition: filter 0.5s;
						transform-origin: center bottom;
						will-change: opacity, transform;
						@media screen and (max-width: 1920px) {
							padding: 1.56vw;
							// border-radius: 0.78vw;
							border-radius: 1.56vw;
							font-size: 2.6vw;
							letter-spacing: -0.07vw;
						}
						@media screen and (max-width: 600px) {
							font-size: 5vw;
							padding: 0.5vw;
						}
					}
				}
			}

			// .box-container {
			// 	display: flex;
			// width: 100%;
			// height: 100%;
			// margin: 0 50px;
			// @media screen and (max-width: 1920px) {
			// 	margin: 0 2.6vw;
			// }
			// 	.box-column {
			// 		width: 33.33%;
			// 		margin: 20px;
			// 		@media screen and (max-width: 1920px) {
			// 			margin: 0 1.04vw;
			// 		}
			// 	}
			// }
		}

		.arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 25px 0;
			grid-column: 1/2;
			grid-row: 3/4;
			@media screen and (max-width: 800px) {
				margin: 25px 0 0;
				& > svg {
					transform: scale(0.5);
				}
			}
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 25px 0;
		}
	}
	.particles-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
