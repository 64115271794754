.wheel-media {
	width: 100%;
	height: 100%;
	background-image: url(../../../assets/wheel-media.png);
	position: absolute;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(45deg);
}

.wheel-creative {
	width: 100%;
	height: 100%;
	background-image: url(../../../assets/wheel-creative.png);
	position: absolute;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(155deg);
}

.wheel-adtech {
	width: 100%;
	height: 100%;
	background-image: url(../../../assets/wheel-adtech.png);
	position: absolute;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(270deg);
}

.wheel-skin-lines {
	background-image: url(../../../assets/wheel-skin-lines2.png);
	width: 1600px;
	height: 1600px;
	background-size: 50%;
	position: absolute;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.3;
	overflow: hidden;
	transition: opacity 1s ease 0.2s, transform 1s ease 0.2s;
	@media screen and (max-width: 1920px) {
		width: 83.33vw;
		height: 83.33vw;
	}
	@media screen and (max-width: 600px) {
		width: 170vw;
		height: 150vw;
	}
}

.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	position: relative;
	overflow: hidden;
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		max-height: 1080px;
		display: grid;
		// grid-template-columns: 1fr 1fr;
		grid-template-columns: repeat(2, 50%);
		grid-template-rows: 1fr 1fr 89px;
		padding: 50px;
		gap: 50px;
		@media screen and (max-width: 600px) {
			grid-template-columns: 100%;
			grid-template-rows: auto auto auto 89px;
			padding: 25px;
			max-height: 1920px;
		}
		.title-container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			grid-column: 1/2;
			grid-row: 1/2;
			@media screen and (max-width: 600px) {
				height: auto;
			}

			.title {
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.56;
				text-align: left;
				color: #ffffff;
				font-size: 67px;
				letter-spacing: -1.34px;
				@media screen and (max-width: 1920px) {
					font-size: 3.48vw;
					letter-spacing: -0.07vw;
				}
				@media screen and (max-width: 600px) {
					font-size: 7.5vw;
				}
			}
			.text {
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				text-align: left;
				color: #ffffff;
				font-size: 23px;
				letter-spacing: -0.5px;
				@media screen and (max-width: 1920px) {
					font-size: 1.19vw;
					letter-spacing: -0.02vw;
				}
				@media screen and (max-width: 600px) {
					font-size: 3.5vw;
				}
				& > br {
					@media screen and (max-width: 600px) {
						display: none;
					}
				}
				& > br:nth-child(1),
				br:nth-child(2) {
					display: block;
				}
			}
			// }
		}

		.wheel-box-container {
			grid-column: 1/2;
			grid-row: 2/3;
			width: 100%;
			height: 100%;
			@media screen and (max-width: 600px) {
				grid-column: 1/2;
				grid-row: 3/4;
			}
		}
	}

	.wheel-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		grid-column: 2/3;
		grid-row: 1/3;
		@media screen and (max-width: 600px) {
			margin-right: 0;
			grid-column: 1/2;
			grid-row: 2/3;
		}
	}
	.arrow {
		display: flex;
		grid-column: 1/3;
		grid-row: 3/4;
		justify-content: center;
		align-items: center;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 25px 0;
		@media screen and (max-width: 600px) {
			margin: 0 0 25px;
			grid-column: 1/2;
			grid-row: 4/5;
			& > svg {
				transform: scale(0.5);
			}
		}
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 25px 0;
		@media screen and (max-width: 600px) {
			& > svg {
				transform: scale(0.5);
			}
		}
	}
}
