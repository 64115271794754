.route {
	color: #0c3f57;
	.container {
		padding: 50px;
		@media screen and (max-width: 600px) {
			padding: 25px;
		}

		li {
			margin-bottom: 20px;
		}

		// a {
		// 	margin-bottom: 10px;
		// 	font-weight: bold;
		// }

		p {
			line-height: 1.5;
			margin: 0;
			margin-bottom: 10px;
			margin-left: 10px;
		}

		h2 {
			line-height: 1.5;
			margin: 0;
			margin-bottom: 5px;
		}
	}
}
