.route.about {
  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    will-change: opacity;
    & > img {
      will-change: opacity;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .aboutRouteWrapper {
    display: grid;
    grid-template-rows: repeat(6, auto);
  }

  .arrowContainer {
    height: 20vh;

    .arrow {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.firstType {
        position: relative;
        top: 10%;
      }

      @media screen and (max-width: 600px) {
        &.firstType {
          top: 0;
        }
        & > svg {
          transform: scale(0.5);
        }
      }
    }
  }
}
