.footer {
  @media screen and (max-width: 600px) {
    display: none;
  }
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 50px;
  font-size: 29px;
  font-weight: bold;
  color: #0c3f57;
  .sitemap-container {
    display: flex;
    flex-direction: column;
    grid-column: 1/4;
    align-items: flex-start;
    & > .link {
      margin-bottom: 25px;
    }
    :last-child {
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      a {
        font-size: 23px;
        margin-bottom: 15px;
      }
    }
  }

  .offices-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column: 5/9;
    & .column-title {
      margin-bottom: 25px;
    }
    & > .link {
      font-size: 23px;
      margin-bottom: 15px;
    }
  }

  .contacts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column: 9/13;
    & .column-title {
      margin-bottom: 25px;
    }
    & .contacts-inner {
      font-size: 23px;
      margin-bottom: 25px;
      & .contact-subtitle {
        margin-bottom: 5px;
      }
    }
    // & .linkedin-container {
    // 	.social-icon {
    // 		width: 61px;
    // 		height: 61px;
    // 	}
    // }
  }

  .marketing-partners {
    display: flex;
    flex-direction: column;
    grid-column: 1/5;
    .marketing-title {
      font-size: 11.5px;
      line-height: 2.35;
      margin-bottom: 5px;
    }

    .marketing-icons {
      display: flex;
    }
    .social-icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      & > img {
        width: 100%;
      }
    }
  }

  .footer-logo {
    display: flex;
    grid-column: 9/13;
    justify-content: flex-end;
    & > img {
      width: 76px;
      height: 76px;
    }
  }

  &.dark {
    // background-color: #446c7c;
    color: #fff;
  }

  &.light {
    // background-color: #fff;
    color: #0c3f57;
  }
}
