.section {
	display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    /* min-height: 100vh; */
    position: relative;
    padding: 0 50px 10vh;
	@media screen and (max-width: 600px) {
		padding: 0 25px 15vh 25px
	}
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		// max-height: 1080px;
		position: relative;
		display: grid;
		grid-template-columns: 0.75fr 0.25fr;
		// grid-template-rows: 0.15fr 0.85fr;
		grid-template-rows: 0.15fr 0.85fr;
		gap: 50px;
		@media screen and (max-width: 600px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			gap: 25px;
		
		}
		.top {
			width: 100%;
			height: 100%;
			grid-column: 1/3;
			grid-row: 1/2;
			display: flex;
			align-items: center;
			@media screen and (max-width: 600px) {
				grid-column: 1/3;
				// grid-row: 1/2;
				// grid-row: 2/3;
			}
			.title-container {
				display: flex;
				justify-content: center;
				flex-direction: column;
				.title {
					font-weight: bold;
					line-height: 1.56;
					color: #ffffff;
					font-size: 67px;
					letter-spacing: -1.34px;
					@media screen and (max-width: 1920px) {
						font-size: 3.48vw;
						letter-spacing: -0.06vw;
					}
					@media screen and(max-width: 600px) {
						font-size: 7.5vw;
					}
				}
				.text {
					font-weight: 500;
					line-height: 1.5;
					color: #ffffff;
					font-size: 23px;
					letter-spacing: -0.5px;
					@media screen and (max-width: 1920px) {
						font-size: 1.35vw;
						letter-spacing: -0.026vw;
					}
					@media screen and(max-width: 600px) {
						font-size: 3.5vw;
						br {
							display: none;
						}
					}
				}
			}
		}
		.map-container {
			width: 100%;
			height: 100%;
			grid-column: 1/2;
			grid-row: 2/3;
			@media screen and (max-width: 600px) {
				display: none;
			}
			& .map-wrapper {
				position: relative;
				& > img {
					width: 100%;
				}
			}
		}
		.right {
			width: 100%;
			height: 100%;
			grid-column: 2/3;
			grid-row: 2/3;
			@media screen and(max-width: 600px) {
				grid-column: 1/2;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 25px;
			}
		}

		}
		.arrow {
			display: flex;
			grid-column: 1/3;
			grid-row: 1/2;
			justify-content: center;
			align-items: center;
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 25px 0;
			@media screen and (max-width: 600px) {
				margin: 10px 0;
				& > svg {
					transform: scale(0.5);
				}
			}
			bottom: 0;
			left: 0;
			width: 100%;
			@media screen and (max-width: 600px) {
				& > svg {
					transform: scale(0.5);
				}
			}
		}
	}

// .section.about.our-locations {
// 	.container {
// 		@media screen and (max-width: 600px) {
// 			position: relative;
// 			max-height: none;
// 			height: auto;
// 		}
// 		.left {
// 			width: 75%;
// 			display: flex;
// 			justify-content: center;
// 			flex-direction: column;
// 			flex-shrink: 0;
// 			@media screen and(max-width: 600px) {
// 				width: 100%;
// 			}
// 			.title-container {
// 				display: flex;
// 				justify-content: center;
// 				flex-direction: column;
// 				height: 25%;
// 				margin-left: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-left: 2.6vw;
// 				}
// 				@media screen and (max-width: 600px) {
// 					margin-right: 2.6vw;
// 					margin-bottom: 5.2vw;
// 				}
// 				.title {
// 					font-weight: bold;
// 					line-height: 1.56;
// 					color: #ffffff;
// 					font-size: 67px;
// 					letter-spacing: -1.34px;
// 					@media screen and (max-width: 1920px) {
// 						font-size: 3.48vw;
// 						letter-spacing: -0.06vw;
// 					}
// 					@media screen and(max-width: 600px) {
// 						font-size: 7.5vw;
// 					}
// 				}
// 				.text {
// 					font-weight: 500;
// 					line-height: 1.31;
// 					color: #ffffff;
// 					font-size: 26px;
// 					letter-spacing: -0.52px;
// 					@media screen and (max-width: 1920px) {
// 						font-size: 1.35vw;
// 						letter-spacing: -0.02vw;
// 					}
// 					@media screen and(max-width: 600px) {
// 						font-size: 3.5vw;
// 						br {
// 							display: none;
// 						}
// 					}
// 				}
// 			}
// 			.map-container {
// 				position: relative;
// 				display: flex;
// 				justify-content: center;
// 				flex-direction: column;
// 				align-items: center;
// 				height: 610px;
// 				margin-left: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-left: 2.6vw;
// 					height: 31.77vw;
// 				}
// 				@media screen and (max-width: 600px) {
// 					display: none;
// 				}
// 				& > img {
// 					width: 100%;
// 				}
// 			}
// 		}
// 		.right {
// 			width: 25%;
// 			@media screen and(max-width: 600px) {
// 				width: auto;
// 				display: grid;
// 				grid-template-columns: 1fr 1fr;
// 				margin-left: 2.6vw;
// 				margin-right: 2.6vw;
// 			}
// .office-container {
// 	width: 100%;
// 	height: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	justify-content: flex-end;
// 	@media screen and (max-width: 600px) {
// 		display: grid;
// 		grid-template-rows: auto;
// 		padding: 2.6vw;
// 		justify-content: flex-start;
// 	}
// 	.office-image-container {
// 		overflow: hidden;
// 		width: 355px;
// 		height: 248px;
// 		border-radius: 15px;
// 		margin-bottom: 20px;
// 		display: flex;
// 		@media screen and (max-width: 1920px) {
// 			width: 18.48vw;
// 			height: 12.91vw;
// 			border-radius: 0.78vw;
// 			margin-bottom: 1.04vw;
// 		}
// 		@media screen and (max-width: 600px) {
// 			width: auto;
// 			height: auto;
// 			margin-bottom: 2.6vw;
// 		}
// 		.office-image {
// 			width: 100%;
// 			height: 100%;
// 		}
// 	}
// 	.office-info-container {
// 		margin-bottom: 150px;
// 		display: flex;
// 		flex-direction: column;
// 		@media screen and (max-width: 1920px) {
// 			margin-bottom: 7.81vw;
// 		}
// 		.name {
// 			font-weight: bold;
// 			color: #ffffff;
// 			font-size: 62.5px;
// 			letter-spacing: -1.25px;
// 			margin-bottom: 10px;
// 			@media screen and (max-width: 1920px) {
// 				font-size: 3.25vw;
// 				letter-spacing: -0.06vw;
// 				margin-bottom: 0.52vw;
// 			}
// 			@media screen and (max-width: 600px) {
// 				font-size: 6vw;
// 			}
// 		}
// 		.address,
// 		.email {
// 			font-weight: 500;
// 			font-stretch: normal;
// 			font-style: normal;
// 			line-height: 1.38;
// 			text-align: left;
// 			color: #ffffff;
// 			font-size: 25px;
// 			letter-spacing: -0.5px;
// 			@media screen and (max-width: 1920px) {
// 				font-size: 1.3vw;
// 				letter-spacing: -0.02vw;
// 			}
// 			@media screen and(max-width: 600px) {
// 				font-size: 3.5vw;
// 			}
// 		}
// 		.email {
// 			color: #f08cb9;
// 		}
// 	}
// }
// 		}
// 	}
// }
