button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  padding: 0px;
  margin: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button:hover,
button:focus {
    background-color: rgba(255, 255, 255, 0);
}

button:focus {
    outline: none;
    outline-offset: 0px;
}

button:active {
    transform: none;
}