.important-point-container {
	font-size: 37px;
	line-height: 1.08;
	text-align: center;
	color: #0e374d;
	display: flex;
	width: 20%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	white-space: nowrap;
	@media screen and (max-width: 1920px) {
		font-size: 1.92vw;
		margin: 0 1.5vw;
	}
	@media screen and (max-width: 600px) {
		width: 50%;
		margin: 0;
		font-size: 5vw;
		margin-bottom: 5vw;
	}
	.important-image {
		width: 50px;
		height: 50px;
		margin-bottom: 35px;
		@media screen and (max-width: 1920px) {
			width: 2.6vw;
			height: 2.6vw;
			margin-bottom: 1.82vw;
		}
		@media screen and (max-width: 600px) {
			width: 6vw;
			height: 6vw;
			margin-bottom: 1.82vw;
		}
	}
	.important-title {
		font-weight: bold;
		font-size: 100%;
		margin-bottom: 35px;
		@media screen and (max-width: 1920px) {
			margin-bottom: 1.82vw;
		}
	}
	.important-text {
		font-size: 45.94%;
		font-weight: 500;
	}
}
