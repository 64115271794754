@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.section.vertical.double.home.case-studies {
	width: calc(100vw - (100vw - 100%));
	height: 100vh;
	justify-content: center;
	align-items: center;
	.container {
		width: 100%;
		height: 100%;
		// max-width: 1920px;
		max-height: 1080px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 0.15fr 0.65fr;
		.top {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			.title-container {
				.title {
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.56;
					text-align: center;
					color: #063a53;
					font-size: 67px;
					letter-spacing: -1.34px;
					@media screen and (max-width: 1920px) {
						font-size: 3.48vw;
						letter-spacing: -0.071vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 7.5vw;
					}
				}
			}
		}
		.bottom {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			position: relative;
			.glide {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%);
				overflow-x: hidden;
				& * {
					will-change: auto;
					box-sizing: border-box;
				}
				.glide__arrows {
					width: 100%;
					// max-width: 1920px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%);
					pointer-events: none;
					.glide__arrow {
						position: absolute;
						top: 0;
						width: 50px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						pointer-events: all;
						&:first-child:hover {
							animation: pulse-left 0.5s ease infinite;
						}
						&:last-child:hover {
							animation: pulse-right 0.5s ease infinite;
						}
						@keyframes pulse-left {
							50% {
								transform: translateX((-10%));
							}
							100% {
								transform: translateX((0));
							}
						}
						@keyframes pulse-right {
							50% {
								transform: translateX((10%));
							}
							100% {
								transform: translateX((0));
							}
						}
						@media screen and (max-width: 1920px) {
							width: 2.6vw;
						}
						@media screen and (max-width: 600px) {
							width: 7.5vw;
						}
					}
					.glide__arrow--left {
						left: 0;
						& > svg {
							transform: rotate(90deg);
						}
					}
					.glide__arrow--right {
						right: 0;
						& > svg {
							transform: rotate(-90deg);
						}
					}
				}
				.case-study-link {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 1;
				}
				.glide__track {
					height: 100%;
					.glide__slides {
						height: 100%;
						margin: 0;
						.glide__slide {
							position: relative;
							overflow: hidden;
							width: 757px;
							height: 100%;
							border-radius: 25px;
							& > img {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							& .slide-container {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								display: flex;
								flex-direction: column;
								color: #fff;
								font-weight: bold;
								padding: 50px;
								@media screen and (max-width: 1920px) {
									padding: 2.6vw;
								}
								@media screen and (max-width: 600px) {
									padding: 5.2vw;
								}
								.slide-title {
									font-size: 50px;
									@media screen and (max-width: 1920px) {
										font-size: 2.6vw;
									}
									@media screen and (max-width: 600px) {
										font-size: 10vw;
									}
								}
								.slide-text {
									font-size: 25px;
									@media screen and (max-width: 1920px) {
										font-size: 1.3vw;
									}
									@media screen and (max-width: 600px) {
										font-size: 4vw;
									}
								}
								&.centered {
									justify-content: center;
								}
								&.end {
									justify-content: flex-end;
								}
								&.start {
									justify-content: flex-start;
								}
							}
							// @media screen and (max-width: 1920px) {
							// 	width: 39.42vw;
							// 	height: 27.6vw;
							// 	border-radius: 1.3vw;
							// 	padding: 2.6vw;
							// }
							// @media screen and (max-width: 600px) {
							// 	width: 75vw;
							// 	height: 100vw;
							// 	border-radius: 1.3vw;
							// 	padding: 2.6vw;
							// }
						}
					}
				}
			}
		}
	}
}
