.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	// height: 100vh;
	position: relative;
	@media screen and (max-width: 600px) {
		// height: 75vh;
	}
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		max-height: 1080px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		// grid-template-rows: 1fr 49px;
		grid-template-rows: 1fr;
		overflow-x: hidden;
		.single {
			display: grid;
			grid-template-columns: 1fr auto 1fr;
			// grid-template-rows: 1fr auto 1fr;	//even space between arrows on mobile
			grid-template-rows: 1fr auto 0.9fr;
			width: 100%;
			// height: calc(100% - 89px);
			@media screen and (max-width: 600px) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
			}
			.dynamic-creative {
				display: flex;
				grid-column: 1/2;
				grid-row: 1/2;
				align-items: flex-end;
				justify-content: flex-end;
				text-align: right;
				// opacity: 0;
				@media screen and (max-width: 600px) {
					text-align: center;
				}
			}
			.plus {
				grid-column: 2/3;
				grid-row: 2/3;
				@media screen and (max-width: 600px) {
				}
			}
			.optimization {
				grid-column: 3/4;
				grid-row: 3/4;
				// opacity: 0;
				@media screen and (max-width: 600px) {
					margin-top: 12.5vw;
				}
			}
			.title-container {
				.title {
					font-weight: bold;
					line-height: 0.95;
					color: #0e374d;
					font-size: 77.5px;
					letter-spacing: -1.55px;
					margin-bottom: 20px;
					@media screen and (max-width: 1920px) {
						font-size: 4.03vw;
						letter-spacing: -0.08vw;
						margin-bottom: 1.041vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 10vw;
						margin-bottom: 2.5vw;
					}
				}
				.text {
					font-weight: 500;
					line-height: 1.18;
					color: #0e374d;
					font-size: 38px;
					letter-spacing: -0.15px;
					@media screen and (max-width: 1920px) {
						font-size: 1.97vw;
						letter-spacing: -0.007vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 4.5vw;
						margin-bottom: 5vw;
					}
				}
			}

			.plus {
				line-height: 0.5;
				color: #0e374d;
				font-size: 200px;
				// opacity: 0;
				// transform: scale(0.5);
				@media screen and (max-width: 1920px) {
					font-size: 10.41vw;
				}
				@media screen and (max-width: 600px) {
					font-size: 20vw;
					margin-top: 5vw;
				}
			}
		}
	}
	.particles-container {
		width: 100%;
		height: 100%;
	}

	// .arrow {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	margin: 25px 0;
	// 	@media screen and (max-width: 600px) {
	// 		margin: 75px 0;
	// 		& > svg {
	// 			transform: scale(0.5);
	// 		}
	// 	}
	// }
}
