.office-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	@media screen and (max-width: 600px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		justify-content: flex-start;
		gap: 25px;
	}
	.office-image-container {
		overflow: hidden;
		width: 355px;
		height: 248px;
		border-radius: 15px;
		margin-bottom: 20px;
		display: flex;
		@media screen and (max-width: 1920px) {
			width: 18.48vw;
			height: 12.91vw;
			border-radius: 0.78vw;
			margin-bottom: 1.04vw;
		}
		@media screen and (max-width: 600px) {
			width: auto;
			height: auto;
			gap: 25px;
			margin-bottom: 0;
		}
		.office-image {
			width: 100%;
			height: 100%;
		}
	}
	.office-info-container {
		margin-bottom: 150px;
		display: flex;
		flex-direction: column;
		@media screen and (max-width: 1920px) {
			margin-bottom: 7.81vw;
		}
		@media screen and (max-width: 600px) {
			margin-bottom: 0;
		}
		.name {
			font-weight: bold;
			color: #ffffff;
			font-size: 62.5px;
			letter-spacing: -1.25px;
			margin-bottom: 10px;
			@media screen and (max-width: 1920px) {
				font-size: 3.25vw;
				letter-spacing: -0.06vw;
				margin-bottom: 0.52vw;
			}
			@media screen and (max-width: 600px) {
				font-size: 6vw;
			}
		}
		.address,
		.email {
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.38;
			text-align: left;
			color: #ffffff;
			font-size: 25px;
			letter-spacing: -0.5px;
			@media screen and (max-width: 1920px) {
				font-size: 1.3vw;
				letter-spacing: -0.02vw;
			}
			@media screen and(max-width: 600px) {
				font-size: 3.5vw;
			}
		}
		.email {
			color: #f08cb9;
		}
	}
}
