.job-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 12.5%;
	margin: 20px;
	margin: 1.04vw;
	@media screen and (max-width: 600px) {
		width: 25%;
	}
	.job-image {
		width: 100px;
		height: 100px;
		@media screen and (max-width: 1920px) {
			width: 5.2vw;
			height: 5.2vw;
		}
		@media screen and (max-width: 600px) {
			width: 12.5vw;
			height: 12.5vw;
		}
	}
	.job-title {
		font-size: 24px;
		line-height: 1.58;
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		position: absolute;
		@media screen and (max-width: 1920px) {
			font-size: 1.25vw;
		}
		@media screen and (max-width: 600px) {
			font-size: 3vw;
		}
	}
}
