.container {
	display: flex;
	margin-top: 89px;
	@media screen and (max-width: 600px) {
		margin-top: 65px;
	}
	a {
		cursor: pointer;
		padding: 5px 10px;
		font-weight: bold;
	}
	.spacer {
		margin: 5px 10px;
		font-weight: bolder;
		width: 2px;
		height: 20px;
		background-color: #0c3f57;
		flex-shrink: 0;
	}
}
