.office-icon {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #f08cb9;
  transform: scale(2);
  transition: background-color 0.25s ease;
  cursor: pointer;
  &:hover {
    background-color: #f074ab;
  }

  &.active {
    background-color: #f074ab;
  }
  @media screen and (max-width: 1920px) {
    width: 0.52vw;
    height: 0.52vw;
  }
}
