/* .SimplePlane{
  width:35vw !important;
  height:35vw !important;
} */
/* /* curtains canvas */

/* .curtains-canvas {
    top: 0px;
    left: 6vw;
    width: 40vw;
    height: 40vw;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    overflow: hidden;
    position: fixed;
  } */


  .curtains-canvas {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    top: 0;
  }


/* .SimplePlane {
    width: ;
    height: calc(100% - 99px);
    max-width: 1920px;
    top: -5vh;
    left:15vw;
    overflow: hidden;
  }
  
  .SimplePlane img {
    display: none;
    width:50vw;
    height:50vh;
    overflow: hidden;
  } */

  .SimplePlane {
    width: 40vw;
    height: 40vh;
    min-height: 40vw;
  }
  
  .SimplePlane img {
    display: none;
    width: 40vw;
    height: 40vh;
    min-height: 40vw;
  }
/* 

  .hero-logo-animation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
} */

/* @media screen and (min-width: 1500px) {
  .mobile-logo{
    display: block;
  }
  .hero-logo-animation {
    display:none;
  }
  .curtains-canvas {
    display:none;
  }
}		 */