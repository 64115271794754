
body {
  margin: 0;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior: none;
}

body * {
  box-sizing: border-box;
}

// ::selection {
// 	background: #ffffff75; /* WebKit/Blink Browsers */
// }
// ::-moz-selection {
// 	background: #ffffff75; /* Gecko Browsers */
// }

// .section {
// 	position: relative;
// 	height: 56.25vw;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	overflow-x: hidden;
// 	@media screen and (max-width: 600px) {
// 		min-height: 177.777vw;
// 		height: 100%;
// 	}
// 	&:nth-child(2n) {
// 		// background-color: #eee;
// 	}
// 	.container,
// 	.container-reverse {
// 		display: flex;
// 		width: 100%;
// 		max-width: 1920px;
// 		height: 100%;
// 		max-height: 1080px;
// 		position: relative;
// 		@media screen and (max-width: 1920px) {
// 			position: absolute;
// 			top: 0;
// 			left: 0;
// 		}
// 	}
// 	&.vertical {
// 		&.double {
// 			.container {
// 				flex-direction: column;
// 				.top {
// 					height: 50%;
// 					// background-color: orchid;
// 				}

// 				.bottom {
// 					height: 50%;
// 					// background-color: hotpink;
// 					margin-bottom: 130px;
// 					@media screen and (max-width: 1920px) {
// 						margin-bottom: 6.77vw;
// 					}
// 				}
// 			}
// 		}
// 		&.triple {
// 			.container {
// 				flex-direction: column;
// 				.top {
// 					height: 33.33%;
// 					// background-color: burlywood;
// 				}

// 				.middle {
// 					height: 33.33%;
// 					// background-color: salmon;
// 				}

// 				.bottom {
// 					height: 33.33%;
// 					// background-color: slateblue;
// 				}
// 			}
// 		}
// 	}
// 	&.horizontal {
// 		.container {
// 			flex-direction: row;
// 			@media screen and (max-width: 600px) {
// 				flex-direction: column;
// 			}
// 			.left {
// 				width: 50%;
// 				margin-right: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-right: 2.6vw;
// 				}
// 				@media screen and (max-width: 600px) {
// 					width: 100%;
// 					height: 50%;
// 					margin-right: 0;
// 				}
// 			}

// 			.right {
// 				width: 50%;
// 				// background-color: blue;
// 				margin-left: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-left: 2.6vw;
// 				}
// 				@media screen and (max-width: 600px) {
// 					width: 100%;
// 					height: 50%;
// 					margin-left: 0;
// 				}
// 			}
// 		}
// 		.container-reverse {
// 			flex-direction: row-reverse;
// 			.left {
// 				width: 50%;
// 				// background-color: red;
// 				margin-left: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-left: 2.6vw;
// 				}
// 			}

// 			.right {
// 				width: 50%;
// 				// background-color: blue;
// 				margin-right: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-right: 2.6vw;
// 				}
// 			}
// 		}
// 	}
// 	&.single {
// 		.container {
// 			.single {
// 				width: 100%;
// 				height: 100%;
// 				margin-left: 50px;
// 				margin-right: 50px;
// 				@media screen and (max-width: 1920px) {
// 					margin-left: 2.6vw;
// 					margin-right: 2.6vw;
// 				}
// 				// background-color: orange;
// 			}
// 		}
// 	}

// 	&.double-horizontal-single-vertical {
// 		// height: 76.25vw;
// 		.container {
// 			display: grid;
// 			grid-template-columns: 1fr 1fr;
// 			grid-template-row: 1fr 1fr;
// 			.left {
// 				grid-column: 1/2;
// 				grid-row: 1/2;
// 			}
// 			.right {
// 				grid-column: 2/3;
// 				grid-row: 1/2;
// 			}
// 			.bottom {
// 				grid-column: 1/3;
// 				grid-row: 2/3;
// 			}
// 		}
// 	}

// 	.down-arrow {
// 		position: absolute;
// 		bottom: 0;
// 		left: 0;
// 		width: 100%;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		margin-bottom: 65px;
// 		@media screen and (max-width: 1920px) {
// 			margin-bottom: 3.38vw;
// 		}
// 		& > svg {
// 			width: 62px;
// 			height: 39px;
// 			@media screen and (max-width: 1920px) {
// 				width: 3.22vw;
// 				height: 2.03vw;
// 			}
// 			@media screen and (max-width: 600px) {
// 				width: 7.22vw;
// 				height: 5.03vw;
// 			}
// 		}
// 	}
// }

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.section-overflown {
  overflow-x: initial;
}

.page-container {
  position: relative;
}

.route {
  position: absolute;
  left: 0;
  right: 0;
  will-change: opacity;
}

.page-enter, .about-location-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  //transition: opacity 1000ms;
  // transition: opacity 500ms linear 500ms;
  transition: opacity 500ms linear 500ms;
}

.page-exit, .about-location-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  // transition: opacity 1000ms;
  transition: opacity 500ms;
}

.about-location-enter-active {
  opacity: 1;
  transition: opacity 0ms;
}

.about-location-exit-active {
  opacity: 0;
  transition: opacity 0ms;
}