.footer {

  @media screen and (min-width: 600px) {
  display: none;
  }

  display: grid;
  grid-template-rows: repeat(3, 50px)  auto auto;
  // grid-template-rows: repeat(6, auto);
  grid-template-columns: 41.5% 41.5%;
  column-gap: 17%;
  grid-template-areas:
    "homeLink getInTouchTitle"
    "howItWorksLink enquiriesInner"
    "aboutLink jobsInner"
    // "caseStudiesLink jobsInner"
    // "aboutLink ."
    "antiSlaveryLink ."
    "logosWrapper logosWrapper";
  font-weight: bold;
  color: #0c3f57;
  font-size: 18px;
  padding: 20px;

  .link {
    &.home {
      grid-area: homeLink;
    }
    &.howItWorks {
      grid-area: howItWorksLink;
    }
    &.about {
      grid-area: aboutLink;
    }
    &.antiSlavery {
      grid-area: antiSlaveryLink;
      font-size: 14px;
    }
    // &.caseStudies {
    //   grid-area: caseStudiesLink;
    // }
  }
  // .aboutAspWrapper {
  //   grid-area: aboutAspWrapper;
  //   display: flex;
  //   flex-direction: column;
  // }

  .column-title {
    &.getInTouch {
      grid-area: getInTouchTitle;
    }
  }

  .contacts-inner {
    display: flex;
    flex-direction: column;
    &.enquiries {
      grid-area: enquiriesInner;
    }
    &.jobs {
      grid-area: jobsInner;
    }
  }

  .contact-subtitle {
    &.jobs {
      grid-area: jobsSubTitle;
    }
  }

  .contactEmail {
    font-size: 14px;
    &.jobs {
      grid-area: jobsEmail;
    }
  }

  .logosWrapper {
    margin-top: 15px;
    grid-area: logosWrapper;
    display: grid;
    grid-template-columns: 41.5% 25%;
    column-gap: 33.5%;
  }

  .marketing-partners {
    .marketing-title {
      font-size: 11.5px;
      line-height: 2.35;
      margin-bottom: 5px;
      @media screen and (max-width: 600px) {
        font-size: 2.2vw;
      }
    }

    .marketing-icons {
      display: flex;
    }
    .social-icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
      & > img {
        width: 100%;
      }
    }
  }

  .footer-logo {
    display: flex;
    justify-content: flex-end;
    & > img {
      width: 76px;
      height: 76px;
    }
  }

  &.dark {
    // background-color: #446c7c;
    color: #fff;
  }

  &.light {
    // background-color: #fff;
    color: #0c3f57;
  }
}


