.section {
  @media screen and (min-width: 600px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  // background-color: lightgrey;
  position: relative;
  .container {
    width: 100%;
    height: calc(100% - 99px);
    max-width: 1920px;
    max-height: 1080px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 89px;
    margin: 99px 50px 0 50px;
    @media screen and (max-width: 600px) {
      margin: 65px 25px 0 25px;
      height: calc(100% - 65px);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0.8fr 129px;
    }
    .left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row: 1/2;
      grid-column: 1/2;
      @media screen and (max-width: 600px) {
        grid-row: 1/2;
        grid-column: 1/2;
      }
      .title-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          font-weight: bold;
          line-height: 0.93;
          color: #ffffff;
          font-size: 129px;
          letter-spacing: -2.58px;
          margin-left: 50px;
          .title-line {
            opacity: 0;
            transform: translateY(50%);
          }
          @media screen and (max-width: 1920px) {
            font-size: 6.71vw;
            letter-spacing: -0.13vw;
            margin-left: 2.6vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 15vw;
            margin-left: 0;
          }
        }
      }
    }
    .right {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row: 1/2;
      grid-column: 2/3;
      @media screen and (max-width: 600px) {
        grid-row: 2/3;
        grid-column: 1/2;
      }
      .bullet-container {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .bullet {
          display: flex;
          align-items: center;
          margin-bottom: 75px;
          opacity: 0;
          transform: translateY(50%);
          &:last-child {
            margin-bottom: 0;
          }
          @media screen and (max-width: 1920px) {
            margin-bottom: 3.9vw;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 7.5vw;
          }

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              margin-left: $i * 30px;
              @media screen and (max-width: 1920px) {
                margin-left: $i * 1.56vw;
              }
              @media screen and (max-width: 600px) {
                margin-left: 0;
              }
            }
          }
          .bullet-image-container {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            display: flex;
            margin-right: 30px;
            & > img {
              width: 100%;
              height: 100%;
            }
            @media screen and (max-width: 1920px) {
              width: 1.56vw;
              height: 1.56vw;
              margin-right: 3vw;
            }
            @media screen and (max-width: 600px) {
              width: 5vw;
              height: 5vw;
            }
            .bullet-image {
              width: 100%;
            }
          }
          .bullet-title-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 500;
            line-height: 1.08;
            text-align: left;
            color: #ffffff;
            font-size: 42.5px;
            letter-spacing: -0.85px;
            @media screen and (max-width: 1920px) {
              font-size: 2.213vw;
              letter-spacing: -0.044vw;
            }
            @media screen and (max-width: 600px) {
              font-size: 6vw;
              letter-spacing: -0.075vw;
            }
          }
        }
      }
    }

    .arrow {
      display: flex;
      grid-column: 1/3;
      grid-row: 2/3;
      justify-content: center;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 25px 0;
      @media screen and (max-width: 600px) {
        margin: 45px 0 0;
        grid-column: 1/2;
        grid-row: 3/4;
        & > svg {
          transform: scale(0.5);
        }
      }
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 25px 0;
      @media screen and (max-width: 600px) {
        & > svg {
          transform: scale(0.5);
        }
      }
    }
  }
}
