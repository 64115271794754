.route.case-studies {
	.background-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		will-change: opacity;
		& > img {
			will-change: opacity;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}
	}
}
