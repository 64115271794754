.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	// height: 100vh;
	// min-height: 700px;
	@media screen and (max-width: 600px) {
		// height: 140vh;
		min-height: none;
	}
	.container {
		width: 100%;
		margin-top: 169px;
		// margin-bottom: 7vh;
		max-width: 1920px;
		max-height: 1350px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 22vh;
		@media screen and (max-width: 600px) {
			margin-top: 15vh;
			grid-template-columns: 1fr;
			row-gap: 6vh;
			grid-template-rows: 1fr 1fr 1fr;
		}
		.left {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 1/2;
			padding-left: 50px;
			padding-right: 50px;
			@media screen and (max-width: 600px) {
				grid-row: 1/2;
				grid-column: 1/2;
				padding-left: 25px;
				padding-right: 25px;
			}
			.title-container {
				display: flex;
				flex-direction: column;
				.title {
					font-weight: bold;
					line-height: 0.91;
					color: #ffffff;
					font-size: 154.5px;
					letter-spacing: -3.09px;
					margin-bottom: 75px;
					will-change: opacity, transform;
					transform: translateY(50%);
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 6.71vw;
						letter-spacing: -0.16vw;
						margin-bottom: 3.9vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 15vw;
					}
				}

				.text {
					font-weight: 500;
					line-height: 1.33;
					color: #ffffff;
					font-size: 27px;
					letter-spacing: -0.54px;
					will-change: opacity, transform;
					transform: translateY(50%);
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 1.4vw;
						letter-spacing: -0.02vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 3.5vw;
						margin-top: 3vh;
						br {
							display: none;
						}
					}
				}
			}
		}
		.right {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 50px;
			padding-right: 50px;
			grid-row: 1/2;
			grid-column: 2/3;
			@media screen and (max-width: 600px) {
				grid-row: 2/3;
				grid-column: 1/2;
				padding-left: 25px;
				padding-right: 25px;
				align-items: start;
				justify-content: flex-end;

			}

			& > div {
				transform: translateX(10%);
				opacity: 0;
			}
			.imageContainer {
				position: relative;
				border-radius: 15px;
				overflow: hidden;
				flex-shrink: 0;
				width: 70%;
				display: flex;
				will-change: auto;
				@media screen and (max-width: 600px) {
				width: 100%
				}
				& > video,
				& > img {
				  width: 100%;
				  height: 100%;
				  object-fit: cover;
				}
			}
		}

		.bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding-left: 50px;
			padding-right: 50px;
			grid-row: 2/3;
			grid-column: 1/3;
			@media screen and (max-width: 600px) {
				grid-row: 3/4;
				grid-column: 1/2;
				padding-left: 25px;
				padding-right: 25px;
			}
			.jobs-container {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-around;
				align-items: flex-end;
				@media screen and (max-width: 600px) {
					flex-wrap: wrap;
					align-items: center;
				}
			}
		}



		// .arrow {
		// 	display: flex;
		// 	grid-column: 1/3;
		// 	grid-row: 3/4;
		// 	justify-content: center;
		// 	align-items: center;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	margin: 25px 0;
		// 	@media screen and (max-width: 600px) {
		// 		grid-column: 1/2;
		// 		grid-row: 4/5;
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	margin: 25px 0;
		// 	@media screen and (max-width: 600px) {
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// }
	}
}
