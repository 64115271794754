.route.home {
  .background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    will-change: opacity;
    & > img {
      will-change: opacity;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .homeRouteWrapper {
    display: grid;
    grid-template-rows: repeat(9, auto);
  }
  .arrow {

    &.firstType {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 15vh;
      margin-top: 2.5vw;
      @media screen and (min-width: 1600px) {
        margin-top: 1.5vw;
      }
      @media screen and (max-width: 600px) {
        & > svg {
          transform: scale(0.5);
        }
      }
    }
  }
  .arrowContainer {
    height: 40vh;
    @media screen and (max-width: 600px) {
      height: 30vh;

    }
    .arrow {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 600px) {
        & > svg {
          transform: scale(0.5);
        }
      }
    }
  }
}
