.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  // height: 100vh;
  position: relative;
  .container {
    width: 100%;
	// height: 80%;
    @media screen and (max-width: 600px) {
      height: 100%;
      grid-template-rows: 0.5fr 1fr;
    }
    max-width: 1920px;
    max-height: 1080px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    // grid-template-rows: 0.25fr 0.55fr 0.2fr;
    grid-template-rows: 1fr 1fr;
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      grid-column: 1/2;
      grid-row: 1/2;
      .title-container {
        width: 100%;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: 50%;
          grid-column: 1/2;
          grid-row: 1/2;
          text-align: center;
          font-weight: bold;
          line-height: 1.09;
          color: #0e374d;
          font-size: 50.5px;
          letter-spacing: -1px;
          opacity: 0;
          align-self: start;
          @media screen and (max-width: 1920px) {
            font-size: 2.63vw;
            letter-spacing: -0.052vw;
            width: 60%;
            margin: 0 auto;
          }
          @media screen and (max-width: 600px) {
            font-size: 7.5vw;
            width: 100%;
          }
          &:first-child {
            margin-left: 0;
            margin: 0 auto;
          }
        }
      }
    }
    .bottom {
      display: flex;
      grid-column: 1/2;
      grid-row: 2/3;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      .important-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 600px) {
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
    // .arrow {
    //   display: flex;
    //   grid-column: 1/3;
    //   grid-row: 3/4;
    //   justify-content: center;
    //   align-items: center;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   margin: 50px 0 0;
    //   @media screen and (max-width: 600px) {
    //     grid-column: 1/2;
    //     grid-row: 3/4;
    //     & > svg {
    //       transform: scale(0.5);
    //     }
    //   }
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   margin: 25px 0;
    //   @media screen and (max-width: 600px) {
    //     & > svg {
    //       transform: scale(0.5);
    //     }
    //   }
    // }
  }
}
