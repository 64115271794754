.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  &.is-reversed {
    .container {
      .left {
        grid-column: 2/3;
        @media screen and (max-width: 600px) {
          grid-column: 1/2;
          grid-row: 2/3;
        }
      }
      .right {
        grid-column: 1/2;
        @media screen and (max-width: 600px) {
          grid-row: 1/2;
        }
      }
    }
  }
  .container {
    width: 100%;
    // margin-top: 99px;
    height: calc(100% - 99px);
    max-width: 1920px;
    max-height: 1080px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 49px;
    @media screen and (max-width: 600px) {
      margin-top: 65px;
      height: calc(100% - 65px);
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 89px;
      &.last-child {
        grid-template-rows: 1fr 1fr;
        margin-top: 125px;
      }
    }
    .left {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      // align-items: center;
      grid-row: 1/2;
      grid-column: 1/2;
      margin: 0 50px;
      @media screen and (max-width: 600px) {
        grid-row: 2/3;
        grid-column: 1/2;
        margin: 0 25px;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .description-stats-outer {
        // position: absolute;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        .main-description {
          color: #fff;
          position: relative;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          font-size: 28.5px;
          grid-row-start: 1;
          grid-column-start: 1;
          @media screen and (max-width: 1920px) {
            font-size: 1.184375vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 3.25vw;
          }
        }
      }
      .title-container {
        position: relative;
        color: #fff;
        .small-title {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          font-size: 25.5px;
          @media screen and (max-width: 1920px) {
            font-size: 1.328125vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 5vw;
          }
        }
        .main-title {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          font-size: 101.5px;
          margin-bottom: 25px;
          @media screen and (max-width: 1920px) {
            font-size: 5.2864vw;
            margin-bottom: 1.3vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 10.625vw;
          }
        }
        .small-description {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          font-size: 33px;
          margin-bottom: 25px;
          @media screen and (max-width: 1920px) {
            font-size: 1.71875vw;
            margin-bottom: 1.3vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 4vw;
            margin-bottom: 2.6vw;
          }
        }
        // .stats {
        // margin-top: 4%;
        // @media screen and (max-width: 600px) {
        //   margin-top: 0%;
        // }
        // }
      }
      .stats-container {
        // position: absolute;
        top: 0px;
        left: 0;
        color: #ffa2cb;
        display: flex;
        line-height: 1;
        // width: 100%;
        opacity: 0;
        grid-column-start: 1;
        grid-row-start: 1;
        &.twoElem {
          justify-content: flex-start;
        }
        &.threeElem {
          justify-content: space-between;
        }
      }
    }
    .right {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-row: 1/2;
      grid-column: 2/3;
      margin: 0 50px;
      @media screen and (max-width: 600px) {
        align-items: center;
        grid-row: 1/2;
        grid-column: 1/2;
        margin: 0 25px;
      }
      // .image-container {
      //   overflow: hidden;
      //   position: absolute;
      //   right: -34px;
      //   width: 944px;
      //   height: 661px;
      //   border-radius: 18px;
      //   @media screen and (max-width: 1920px) {
      //     right: -1.77vw;
      //     width: 49.16vw;
      //     height: 34.42vw;
      //     border-radius: 0.93vw;
      //   }
      //   .image {
      //     width: 100%;
      //     height: 100%;
      //     object-fit: cover;
      //   }
      // }
    }

    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      .jobs-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .arrow {
      display: flex;
      grid-column: 1/3;
      grid-row: 2/3;
      justify-content: center;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      // margin: 25px 0;
      margin: 50px 0 0;
      @media screen and (max-width: 600px) {
        margin: 0;
        grid-column: 1/2;
        grid-row: 3/4;
        &.penultimate-child {
          margin: 30px 0 0;
        }
        & > svg {
          transform: scale(0.5);
        }
      }
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .imageContainer {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    flex-shrink: 0;
    width: 87%;
    display: flex;
    will-change: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    & > video,
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
