.stat-inner {
  display: grid;
  grid-template-columns: 10% 80%;
  grid-template-rows: fit-content(100px);
  .stat-title {
    align-items: flex-end;
    display: flex;
    font-weight: bold;
    line-height: 1.34;
    grid-row: 1/1;
    letter-spacing: -0.15px;
    font-size: 1.2239vw;
    @media screen and (max-width: 1920px) {
      font-size: 1.2239vw;
    }
    @media screen and (max-width: 600px) {
      font-size: 3.6717vw;
    }
  }
  .stat-num {
    display: grid;
    grid-template-columns: auto auto;
    grid-row: 2/2;
    .decimal {
      font-size: 60%;
    }
  }
  &.threeElem {
    max-width: 33.33%;
    column-gap: 5px;
    @media screen and (max-width: 600px) {
      column-gap: 0px;
    }
    .stat-num {
      &.reduced {
        font-size: 5.5208vw;
        @media screen and (max-width: 600px) {
          font-size: 12.5208vw;
        }
      }
    }
    .stat-title {
      @media screen and (max-width: 600px) {
        font-size:2.7717vw;
      }

    }
  }
  &.twoElem {
    column-gap: 8px;
    max-width: 50%;
    &:last-of-type {
      margin-left: 1vw;
    }
  }
  .stat-arrow {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 600px) {
      transform: translateY(-5%);
    }
    transform: translateY(-10%);
    img {
      height: 25%;
      position: absolute;
    }
  }

  .number {
    display: grid;
    grid-template-rows: 5vh auto;
    font-size: 154px;
    font-weight: bold;
    letter-spacing: -5px;
    margin-right: 25px;
    font-variant-ligatures: no-common-ligatures;
    font-size: 7.0208vw;
    @media screen and (max-width: 1920px) {
      font-size: 7.0208vw;
      letter-spacing: -0.26vw;
    }
    @media screen and (max-width: 600px) {
      font-size: 14.0416vw;
      letter-spacing: -0.26vw;
    }
    & .arrow > img {
      width: 36px;
      height: 67px;
      margin-right: 25px;
      @media screen and (max-width: 1920px) {
        width: 1.875vw;
        height: 3.4895833333vw;
        margin-right: 1.3vw;
      }
    }
    .unitsWrapper {
      display: flex;
      .plus {
        font-size: 60%;
        transform: translateY(-1vw);
        @media screen and (max-width: 600px) {
          transform: translateY(-2vw);
        }
      }
      .times {
        margin-left: 5px;
        @media screen and (max-width: 600px) {
          margin-left: 2px;
        }
      }
    }
    .unitSymbol {
      font-size: 40%;
    }
  }
}
