.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: relative;
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		max-height: 1080px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 0.75fr 0.25fr;
		@media screen and (max-width: 600px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr 109px;
		}
		.left {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 1/2;
			margin: 0 50px;
			@media screen and (max-width: 600px) {
				grid-row: 1/2;
				grid-column: 1/2;
				margin: 0 25px;
			}
			.title-container {
				.title {
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.56;
					text-align: left;
					color: #ffffff;
					font-size: 67px;
					letter-spacing: -1.34px;
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 3.48vw;
						letter-spacing: -0.06vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 7.5vw;
					}
				}

				.text {
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.3;
					text-align: left;
					color: #ffffff;
					font-size: 23px;
					letter-spacing: -0.5px;
					margin-bottom: 25px;
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 1.19vw;
						letter-spacing: -0.02vw;
						margin-bottom: 1.3vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 3.5vw;
					}
				}
			}

			.partner-container {
				display: flex;
				flex-wrap: wrap;
				.partner-logo {
					width: 25%;
					padding: 2.5%;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0;
					& > img {
						width: 50%;
						@media screen and (max-width: 600px) {
							width: 85%;
						}
					}
				}
			}
		}

		.right {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 2/3;
			padding: 0 50px;
			& > div {
				opacity: 0;
				transform: translateX(10);
			}
			@media screen and (max-width: 600px) {
				grid-row: 2/3;
				grid-column: 1/2;
				padding: 0 25px;
			}
		}
		.arrow {
			display: flex;
			grid-column: 1/3;
			grid-row: 2/3;
			justify-content: center;
			align-items: center;
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 25px 0;
			@media screen and (max-width: 600px) {
				margin: 0;
				grid-column: 1/2;
				grid-row: 3/4;
				& > svg {
					transform: scale(0.5);
				}
			}
			bottom: 0;
			left: 0;
			width: 100%;
			margin: 25px 0;
		}
	}
}
