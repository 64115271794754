.header {
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  // padding: 50px 50px 20px 50px;
  padding: 50px;
  align-items: center;
  z-index: 999;
  @media screen and (max-width: 600px) {
    padding: 20px 50px 20px 50px;
  }
  @media screen and (max-width: 400px) {
    padding: 20px 30px 20px 30px;
  }
  .header-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .logo-container {
    grid-column-start: 1;
    z-index: 1;
    & > a {
      display: flex;
    }
    .logo-image {
      height: 23px;
      @media screen and (max-width: 600px) {
        height: 18px;
      }
    }
  }

  .nav-container {
    display: flex;
    justify-content: flex-end;
    grid-column-end: 13;
    grid-row-start: 1;
    z-index: 1;

    @media screen and (max-width: 900px) {
      display: none;
    }

    a {
      margin: 0 18px;
      flex-shrink: 0;
      font-size: 24px;
      font-weight: bold;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .nav-container-mobile {
    display: none;
    justify-content: flex-end;
    grid-column-end: 13;
    grid-row-start: 1;
    z-index: 1;
    .menu-icon-container {
      height: 30px;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        height: 25px;
      }

      .menu-icon-image {
        height: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      display: flex;
    }
  }

  &.dark {
    // background-color: #446c7c;
    .header-background {
      // opacity: 0.55;
      opacity: 0;
      background-color: #14465c;
    }
    .nav-container {
      a {
        color: #fff;
        border-bottom: none;
        text-decoration: none;
        transition: border-bottom 500ms linear 1000ms;
        &.current {
          border-bottom: 2px solid #fff;
          height: 29px;
        }
      }
    }
  }

  &.light {
    // background-color: #fff;
    .header-background {
      // opacity: 0.35;
      opacity: 0;
      background-color: #ffffff;
    }
    .nav-container {
      a {
        color: #063a53;
        border-bottom: none;
        text-decoration: none;
        transition: border-bottom 500ms linear 1000ms;
        &.current {
          border-bottom: 2px solid #fff;
          height: 28px;
        }
      }
    }
  }
}

.mobile-menu {
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999999;
  pointer-events: none;
  transform: scale(1, 0);
  opacity: 1;
  .header-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .close-wrapper {
    opacity: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 50px 50px 20px 50px;
    align-items: center;
    @media screen and (max-width: 600px) {
      padding: 20px 50px 20px 50px;
    }
    @media screen and (max-width: 400px) {
      padding: 20px 30px 20px 30px;
    }
    .close-icon-container {
      height: 30px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      grid-column-end: 13;
      grid-row-start: 1;
      @media screen and (max-width: 600px) {
        height: 25px;
      }
      .close-icon-image {
        height: 100%;
      }
    }
  }

  .mobile-link-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    div {
      margin-bottom: 30px;
	  opacity: 0;
    }
    a {
      font-size: 36px;
      font-weight: bold;
      color: #063a53;
    }
  }
  &.dark {
    background-color: #446c7c;
    a {
      color: #fff;
      //   border-bottom: none;
      text-decoration: none;
      transition: border-bottom 500ms linear 1000ms;
      &.current {
        border-bottom: 2px solid #fff;
      }
    }
    .header-background {
      opacity: 0.55;
      background-color: #14465c;
    }
  }

  &.light {
    background-color: #fff;
    .header-background {
      opacity: 0.35;
      background-color: #ffffff;
    }
    a {
      color: #063a53;
      //   border-bottom: none;
      text-decoration: none;
      transition: border-bottom 500ms linear 1000ms;
      &.current {
        border-bottom: 2px solid #fff;
      }
    }
  }
}
