.container {
	.section {
		margin-bottom: 25px;

		ul {
			li {
				margin-bottom: 10px;
				a {
					cursor: pointer;
					text-decoration: underline;
					color: #f08cb9;
					font-weight: bold;
				}
			}
		}

		// ul {
		// 	li {
		// 		margin-bottom: 20px;
		// 	}
		// }

		.opn-nav {
			display: flex;
			flex-direction: column;
		}
	}
}
