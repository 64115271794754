.container {
	.section {
		margin-bottom: 25px;
	}
	a {
		cursor: pointer;
		text-decoration: underline;
		color: #f08cb9;
		&:hover {
		}
	}
	.opn-nav {
		display: flex;
		flex-direction: column;
	}
}
