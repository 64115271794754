.swiper-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column-reverse;
	left: 0;
	@media screen and(max-width: 600px) {
		width: 100vw;
		left: -25px;
	}
	.swiper-pagination {
		position: static;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px;
		.swiper-pagination-bullet {
			background-color: #fff;
		}
	}
	.swiper-wrapper {
		background-color: #063a53;
		border-radius: 10px;
		.swiper-slide {
			width: 100%;
			height: 100%;
			background: #fff;
			display: flex;
			width: 100%;
			height: 100%;
			color: #fff;
			background-color: #063a53;
			display: flex;
			flex-direction: column;
			padding: 50px;
			will-change: transform;
			justify-content: center;
			border-radius: 10px;
			@media screen and (max-width: 600px) {
				border-radius: 0;
				padding:25px;
			}
			.mobile-wheel-box-title-container {
				display: flex;
				margin-bottom: 20px;
				.mobile-wheel-box-number {
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: bold;
					line-height: 0.9;
					// color: #f08cb9;
					// border: #f08cb9 solid;
					width: 58px;
					height: 58px;
					font-size: 41.5px;
					border-width: 6px;
					border-radius: 999px;
					margin-right: 20px;
					flex-shrink: 0;
					@media screen and (max-width: 1920px) {
						width: 3.02vw;
						height: 3.02vw;
						font-size: 2.16vw;
						border-width: 0.31vw;
						margin-right: 1.04vw;
					}
					@media screen and (max-width: 600px) {
						width: 45px;
						height: 45px;
						font-size: 30px;
						border-width: 4px;
						margin-right: 20px;
					}
				}
				.mobile-wheel-box-title {
					display: flex;
					align-items: center;
					font-weight: bold;
					line-height: 1;
					color: #ffffff;
					font-size: 38px;
					letter-spacing: -0.76px;
					@media screen and (max-width: 1920px) {
						font-size: 1.97vw;
						letter-spacing: -0.03vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 30px;
					}
				}
			}
			.mobile-wheel-box-text-container {
				.mobile-wheel-box-text {
					line-height: 1.25;
					color: #ffffff;
					font-size: 23px;
					letter-spacing: -0.5px;
					@media screen and (max-width: 1920px) {
						font-size: 1.3vw;
						letter-spacing: -0.02vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 18px;
					}
				}
			}
		}
	}
}

// .wheel-box {
// width: 100%;
// height: 100%;
// color: #fff;
// background-color: #063a53;
// }

// .wheel-box-item {
// 	background-color: #063a53;
// 	width: 100%;
// 	padding: 50px;
// 	border-radius: 10px;
// 	@media screen and (max-width: 1920px) {
// 		padding: 2.6vw;
// 		border-radius: 0.52vw;
// 	}
// 	@media screen and (max-width: 600px) {
// 		padding: 6vw;
// 	}
// 	.wheel-box-title-container {
// 		display: flex;
// 		margin-bottom: 20px;
// 		@media screen and (max-width: 1920px) {
// 			margin-bottom: 1.04vw;
// 		}
// 		@media screen and (max-width: 600px) {
// 			margin-bottom: 2.5vw;
// 		}
// .wheel-box-number {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	font-weight: bold;
// 	line-height: 0.9;
// 	color: #f08cb9;
// 	border: #f08cb9 solid;
// 	width: 58px;
// 	height: 58px;
// 	font-size: 41.5px;
// 	border-width: 6px;
// 	border-radius: 999px;
// 	margin-right: 20px;
// 	@media screen and (max-width: 1920px) {
// 		width: 3.02vw;
// 		height: 3.02vw;
// 		font-size: 2.16vw;
// 		border-width: 0.31vw;
// 		margin-right: 1.04vw;
// 	}
// 	@media screen and (max-width: 600px) {
// 		width: 35px;
// 		height: 35px;
// 		font-size: 24px;
// 		border-width: 2px;
// 		margin-right: 15px;
// 	}
// }
// 		.wheel-box-title {
// 			display: flex;
// 			align-items: center;
// 			font-weight: bold;
// 			line-height: 0.79;
// 			color: #ffffff;
// 			font-size: 38px;
// 			letter-spacing: -0.76px;
// 			@media screen and (max-width: 1920px) {
// 				font-size: 1.97vw;
// 				letter-spacing: -0.03vw;
// 			}
// 			@media screen and (max-width: 600px) {
// 				font-size: 24px;
// 			}
// 		}
// 	}
// .wheel-box-text-container {
// 	.wheel-box-text {
// 		line-height: 1.2;
// 		color: #ffffff;
// 		font-size: 25px;
// 		letter-spacing: -0.5px;
// 		@media screen and (max-width: 1920px) {
// 			font-size: 1.3vw;
// 			letter-spacing: -0.02vw;
// 		}
// 		@media screen and (max-width: 600px) {
// 			font-size: 14px;
// 		}
// 	}
// }
// }
