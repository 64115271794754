.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	// height: 100vh;
	@media screen and (max-width: 600px) {
		// height: 80vh;
	}
	.container {
		width: 100%;
		height: 100%;
		max-width: 1920px;
		max-height: 1080px;
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		// grid-template-rows: 1fr 89px;
		grid-template-rows: 1fr;
		// padding: 50px;
		@media screen and (max-width: 600px) {
			grid-template-columns: 1fr;
			// grid-template-rows: 0.8fr 1.2fr 89px;
			grid-template-rows: 0.8fr 1.2fr;
			// padding: 25px;
			padding: 0 25px;
		}
		.left {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 1/2;
			@media screen and (max-width: 600px) {
				grid-row: 1/2;
				grid-column: 1/2;
			}
			.title-container {
				.title {
					font-weight: bold;
					line-height: 0.93;
					color: #0e374d;
					font-size: 90.5px;
					letter-spacing: -1.81px;
					margin-left: 50px;
					.title-line {
						opacity: 0;
						transform: translateY(50%);
						white-space: nowrap;
					}
					@media screen and (max-width: 1920px) {
						font-size: 4.71vw;
						letter-spacing: -0.094vw;
						margin-left: 2.6vw;
					}

					@media screen and (max-width: 600px) {
						font-size: 10.5vw;
						letter-spacing: -0.25vw;
						margin-left: 0;
					}
					&:first-child {
						font-style: italic;
						font-size: 37px;
						letter-spacing: -0.74px;
						margin-bottom: 10px;
						opacity: 0;
						transform: translateY(50%);
						@media screen and (max-width: 1920px) {
							font-size: 2.5vw;
							letter-spacing: -0.038vw;
							margin-bottom: 1vw;
						}
						@media screen and (max-width: 600px) {
							font-size: 4.5vw;
						}
					}
				}
			}
		}
		.right {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 2/3;
			@media screen and (max-width: 600px) {
				grid-row: 2/3;
				grid-column: 1/2;
			}
			.text-container {
				display: flex;
				flex-direction: column;				
				.text {
					font-weight: 500;
					line-height: 1.5;
					color: #063a53;
					font-size: 31px;
					margin-bottom: 40px;
					opacity: 0;
					@media screen and (max-width: 1920px) {
						font-size: 1.61vw;
						margin-bottom: 1.5vw;
						margin-top:2.8vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 4.2vw;
						margin-bottom: 8.5vw;
					}

					// @media screen and (max-width: 600px) {
					// 	font-size: 5vw;
					// 	margin-bottom: 6vw;
					// }
					& br {
						@media screen and (max-width: 600px) {
							display: none;
						}
					}
				}
			}
			.cta-container {
				display: flex;
				align-items: flex-start;
				opacity: 0;
				.cta {
					font-family: HelveticaNeue;
					font-weight: bold;
					line-height: 0.71;
					text-align: center;
					color: #7dc4b5;
					border: solid #7dc4b5;
					font-size: 26px;
					border-width: 6px;
					padding: 10px 20px;
					border-radius: 999px;
					transition: color 0.25s ease, background-color 0.25s ease;
					cursor: pointer;
					background: #fff;
					&:hover {
						background-color: #7dc4b5;
						color: #fff;
					}
					@media screen and (max-width: 1920px) {
						font-size: 1.35vw;
						border-width: 0.31vw;
						padding: 0.52vw 1.04vw;
						margin-top:1.2vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 20px;
						border-width: 4px;
						padding: 10px 20px;
						margin-bottom: 6vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 3.25vw;
						border-width: 0.8vw;
						padding: 1.75vw 3.5vw;
					}
				}
			}
		}
		// .arrow {
		// 	display: flex;
		// 	grid-column: 1/3;
		// 	grid-row: 2/3;
		// 	justify-content: center;
		// 	align-items: center;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	margin: 25px 0;
		// 	@media screen and (max-width: 600px) {
		// 		margin: 50px 0 0;
		// 		grid-column: 1/2;
		// 		grid-row: 3/4;
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	margin: 25px 0;
		// 	@media screen and (max-width: 600px) {
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// }
		.ctaLink {
			text-decoration: none;
		}
	}
}
