.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: 600px) {
    // min-height: 80vh;
    align-items: start;
    min-height: auto;
    margin-bottom: 10vh;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    max-height: 1080px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-rows: 0.75fr 0.25fr;
    }
    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: 1/2;
      grid-row: 1/2;
      margin: 0 50px;
      @media screen and (max-width: 600px) {
        margin: 0 25px;
      }
      .title-container {
        .title {
          font-weight: bold;
          line-height: 1.04;
          color: #ffffff;
          font-size: 67px;
          letter-spacing: -1.34px;
          margin-bottom: 45px;
          opacity: 0;
          @media screen and (max-width: 1920px) {
            font-size: 3.48vw;
            letter-spacing: -0.06vw;
            margin-bottom: 2.34vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 7.5vw;
          }
        }

        .text {
          font-weight: 500;
          line-height: 1.5;
          color: #ffffff;
          font-size: 23px;
          letter-spacing: -0.5px;
          opacity: 0;
          @media screen and (max-width: 1920px) {
            font-size: 1.35vw;
            letter-spacing: -0.026vw;
          }
          @media screen and (max-width: 600px) {
            font-size: 3.5vw;
          }
        }
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: 2/3;
      grid-row: 1/2;
      margin: 0 50px;
      @media screen and (max-width: 600px) {
        margin: 0 25px;
        grid-column: 1/2;
        grid-row: 2/3;
      }
      .bullet-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 600px) {
          width: 100%;
        }

        .bullet {
          display: flex;
          align-items: center;
          margin-bottom: 45px;
          @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
              margin-left: ($i * 40px) - 40px;
              @media screen and (max-width: 1920px) {
                margin-left: ($i * 2.08vw) - 2.08vw;
                @if ($i == 5) {
                  margin-left: ($i * 2.13vw) - 2.13vw;
                }
              }
              @media screen and (max-width: 600px) {
                margin-left: 0;
              }
            }
          }
          @media screen and (max-width: 1920px) {
            margin-bottom: 2.34vw;
          }
          @media screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 5vw;
          }

          .bullet-image-container {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            display: flex;
            margin-right: 30px;
            & > img {
              width: 100%;
              height: 100%;
            }
            @media screen and (max-width: 1920px) {
              width: 1.56vw;
              height: 1.56vw;
              margin-right: 1.56vw;
            }
            @media screen and (max-width: 600px) {
              width: 5vw;
              height: 5vw;
            }
            .bullet-image {
              width: 100%;
            }
          }
        }
        .bullet-title-container {
          margin-left: 20px;
          .bullet-title {
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            text-align: left;
            color: #ffffff;
            font-size: 31.5px;
            letter-spacing: -0.63px;
            margin-bottom: 10px;
            @media screen and (max-width: 1920px) {
              font-size: 1.64vw;
              letter-spacing: -0.03vw;
              margin-bottom: 0.52vw;
            }
            @media screen and (max-width: 600px) {
              font-size: 3.5vw;
            }
          }

          .bullet-text {
            line-height: 1.33;
            text-align: left;
            color: #ffffff;
            letter-spacing: -0.51px;
            font-size: 25.5px;
            @media screen and (max-width: 1920px) {
              letter-spacing: -0.026vw;
              font-size: 1.32vw;
            }
            @media screen and (max-width: 600px) {
              font-size: 3vw;
            }
          }
        }
      }
    }
  }
}
