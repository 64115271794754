.section.about.what-were-like {
	width: calc(100vw - (100vw - 100%));
	height: 100vh;
	display: flex;
	.container {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: 1fr;
		// grid-template-rows: 0.2fr 1fr 0.2fr 89px;
		grid-template-rows: 0.15fr 1fr 0.2fr;
		// padding: 50px;
		// gap: 50px;
		gap: 20px;
		@media screen and (max-width: 600px) {
			padding: 0px;
			gap: 25px;
		}
		.top {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			opacity: 0;
			grid-column: 1/2;
			grid-row: 1/2;
			@media screen and (max-width: 600px) {
			align-items: center;
			}
			.title-container {
				display: flex;
				margin: 0 50px;
				@media screen and (max-width: 1920px) {
					margin: 0 2.6vw;
				}

				.title {
					font-weight: bold;
					line-height: 1.56;
					color: #ffffff;
					font-size: 67px;
					letter-spacing: -1.34px;
					@media screen and(max-width: 1920px) {
						font-size: 3.48vw;
						letter-spacing: -0.06vw;
					}
					@media screen and(max-width: 600px) {
						font-size: 7.5vw;
					}
				}
			}
		}
		.middle {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			opacity: 0;
			position: relative;
			grid-column: 1/2;
			grid-row: 2/3;
			.glide {
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%);
				overflow-x:hidden;
				& * {
					will-change: auto;
					box-sizing: border-box;
				}
				.glide__arrows {
					width: 100%;
					// max-width: 1920px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%);
					pointer-events: none;
					.glide__arrow {
						position: absolute;
						top: 0;
						width: 50px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						pointer-events: all;
						&:first-child:hover {
							animation: pulse-left 0.5s ease infinite;
						}
						&:last-child:hover {
							animation: pulse-right 0.5s ease infinite;
						}
						@keyframes pulse-left {
							50% {
								transform: translateX((-10%));
							}
							100% {
								transform: translateX((0));
							}
						}
						@keyframes pulse-right {
							50% {
								transform: translateX((10%));
							}
							100% {
								transform: translateX((0));
							}
						}
						@media screen and (max-width: 1920px) {
							width: 2.6vw;
						}
						@media screen and (max-width: 600px) {
							width: 7.5vw;
						}
					}
					.glide__arrow--left {
						left: 0;
						& > svg {
							transform: rotate(90deg);
						}
					}
					.glide__arrow--right {
						right: 0;
						& > svg {
							transform: rotate(-90deg);
						}
					}
				}
				.glide__track {
					height: 100%;
					.glide__slides {
						height: 100%;
						margin: 0;
						.glide__slide {
							position: relative;
							overflow: hidden;
							width: 757px;
							height: 100%;
							border-radius: 25px;
							& > img {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							& .slide-container {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								display: flex;
								background-image: linear-gradient(to top, #000000cc, transparent, transparent);
								flex-direction: column;
								color: #fff;
								font-weight: bold;
								padding: 50px;
								@media screen and (max-width: 1920px) {
									padding: 2.6vw;
								}
								@media screen and (max-width: 600px) {
									padding: 5.2vw;
								}
								.slide-title {
									font-size: 50px;
									@media screen and (max-width: 1920px) {
										font-size: 2.6vw;
									}
									@media screen and (max-width: 600px) {
										font-size: 10vw;
									}
								}
								.slide-text {
									font-size: 25px;
									@media screen and (max-width: 1920px) {
										font-size: 1.6vw;
									}
									@media screen and (max-width: 600px) {
										font-size: 4vw;
									}
								}
								&.centered {
									justify-content: center;
								}
								&.end {
									justify-content: flex-end;
								}
								&.start {
									justify-content: flex-start;
								}
							}
						}
					}
				}
			}
		}
		.bottom {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			opacity: 0;
			grid-column: 1/2;
			grid-row: 3/4;
			.title-container {
				display: flex;
				justify-content: center;
				align-items: center;
				@media screen and (max-width: 1920px) {
					margin: 0 2.6vw;
				}
				.title-wrapper {
					display: flex;
					flex-direction: column;
					margin-right: 20px;
					@media screen and(max-width: 1920px) {
						margin-right: 1.04vw;
					}
					.title {
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.57;
						text-align: right;
						color: #ffffff;
						font-size: 42px;
						letter-spacing: -0.84px;
						@media screen and(max-width: 1920px) {
							font-size: 2.18vw;
							letter-spacing: -0.04vw;
						}
						@media screen and(max-width: 600px) {
							font-size: 6.5vw;
						}
					}
					.text {
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.3;
						text-align: right;
						color: #ffffff;
						font-size: 23px;
						letter-spacing: -0.5px;
						@media screen and(max-width: 1920px) {
							font-size: 1.35vw;
							letter-spacing: -0.026vw;
						}
						@media screen and(max-width: 600px) {
							font-size: 2.5vw;
						}
					}
				}
				.linkedin-wrapper {
					// background-color: #fff;
					width: 61px;
					height: 61px;
					@media screen and(max-width: 1920px) {
						width: 3.177vw;
						height: 3.177vw;
					}
					@media screen and(max-width: 600px) {
						width: 7.354vw;
						height: 7.354vw;
					}
				}
			}
		}
		// .arrow {
		// 	display: flex;
		// 	grid-column: 1/2;
		// 	grid-row: 4/5;
		// 	justify-content: center;
		// 	align-items: center;
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	margin: 25px 0;
		// 	@media screen and (max-width: 600px) {
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// 	bottom: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	@media screen and (max-width: 600px) {
		// 		& > svg {
		// 			transform: scale(0.5);
		// 		}
		// 	}
		// }
	}
}
