.section {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	// height: calc(100vh - 127px);
	// height: 100vh;
	.container {
		width: 100%;
		// margin-top: 99px;
		margin-top: 97px;
		// margin-bottom: 5%;
		// height: calc(100% - 99px);
		max-width: 1920px;
		max-height: 1080px;
		position: relative;
		display: grid;
		row-gap: 10vh;
		grid-template-columns: 1fr 1fr;
		// grid-template-rows: 1fr 89px;
		grid-template-rows: 1fr;
		@media screen and (max-width: 1200px) {
			margin-bottom: 15vh;
		}
		@media screen and (max-width: 600px) {
			margin-top: 15vh;
			row-gap: 7.5vh;
			// height: calc(100% - 65px);
			grid-template-columns: 1fr;
			// grid-template-rows: 1.2fr 0.8fr 89px;
			grid-template-rows: 1.2fr 0.8fr;
			margin-bottom: 10vh;
		}
		.left {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			grid-row: 1/2;
			grid-column: 1/2;
			.mobile-logo{
				display:none;
			}			
			@media screen and (max-width: 1920px) {
				.mobile-logo{
					display:none;
				}
			}
			// @media screen and (min-width: 1500px) {
			// 	.mobile-logo{
			// 		display: block;
			// 	}
			// 	.hero-logo-animation {
			// 		display:none;
			// 	}
			// 	.curtains-canvas {
			// 		display:none;
			// 	}
			// }			
			@media screen and (max-width: 1400px) {
				.mobile-logo{
					display: block;
					padding-right: 3.04vw;
				}
			}			
			@media screen and (max-width: 600px) {
				grid-row: 1/2;
				grid-column: 1/2;
			}
			.logo-container {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				height: 100%;
				opacity: 1;
				@media screen and (max-width: 600px) {
					// height: calc(100% - 65px);
					justify-content: center;
				}

				> img {
					width: 70%;
					max-width:400px;
					object-fit: contain;
				}
			}
		}
		.right {
			width: 80%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			grid-row: 1/2;
			grid-column: 2/3;
			@media screen and (max-width: 600px) {
				width:100%;
				grid-row: 2/3;
				grid-column: 1/2;
				align-items:start;
				justify-content: center;
			}
			.title-container {
				.title {
					font-weight: bold;
					line-height: 0.81;
					text-align: left;
					color: #063a53;
					font-size: 129px;
					letter-spacing: -2.58px;
					margin-bottom: 25px;
					.title-line {
						opacity: 0;
						transform: translateY(50%);
					}
					@media screen and (max-width: 1920px) {
						font-size: 6.71vw;
						letter-spacing: -0.13vw;
						margin-bottom: 1.3vw;
					}
					@media screen and (max-width: 600px) {
						font-size: 15.71vw;
						margin-bottom: 2.5vw;
					}
				}
			}
			.text {
				font-weight: 500;
				line-height: 1.09;
				color: #616161;
				font-size: 33px;
				letter-spacing: -0.65px;
				.text-line {
					opacity: 0;
					transform: translateY(50%);
				}
				@media screen and (max-width: 1920px) {
					font-size: 1.71vw;
					letter-spacing: -0.033vw;
				}
				@media screen and (max-width: 600px) {
					font-size: 3.8vw;
					letter-spacing: 0.26vw;
					padding-top: 3vw;
				}
			}
		}
	}
}
